<template>
  <vue-final-modal v-model="$store.state.modalLikes" classes="flex justify-center items-center" content-class="relative flex flex-col border dark:border-gray-800 rounded bg-white dark:bg-gray-900 modalLikes">
    <button @click="SET_MODAL_LIKES(0)" class="btn btn-pure closeModal"><i class="far fa-window-close"></i></button>
    <h3 class="titulo">LIKES</h3>
    <div class="user_info mt-2 mb-2" v-for="(dt, index) in $store.state.likes" :key="index">
      <div class="profile-image">
        <router-link :to="'/profile/' + dt.liker_id + '/' + dt.username"><img :src="'/users/' + Math.floor(dt.liker_id / 1000) + '/' + dt.username + '/imgp.jpg'"></router-link>
      </div>
      <div class="item-list">
        <router-link :to="'/profile/' + dt.liker_id + '/' + dt.username">{{ dt.username }}</router-link>
        <span class="m-l-5" v-data1>({{ dt.updated_at }})</span>
      </div>
    </div>
    <hr />
    <div class="m-15">
      <button class="button button12" @click="SET_MODAL_LIKES(0)">sair</button>
    </div>
  </vue-final-modal>

  <vue-final-modal v-model="$store.state.modalMsg" classes="flex justify-center items-center" content-class="relative flex flex-col border dark:border-gray-800 rounded bg-white dark:bg-gray-900 modalMessage">
    <button @click="SET_MODAL_MSG(0)" class="btn btn-pure closeModal"><i class="far fa-window-close"></i></button>
    <h3 class="titulo">Escrever Mensagem</h3>
    <div class="mt-2 mb-2 m-l-15 m-r-15">
      <textarea class="form-control h-150" v-model="message"></textarea>
    </div>
    <hr />
    <div class="m-15">
      <button class="button button3" @click="sendMsg">ENVIAR</button>
      <button class="button button12 m-t-15" @click="SET_MODAL_MSG(0)">sair</button>
    </div>
  </vue-final-modal>

  <div v-if="user">
    <div class="container header-wraper header-tools mb-2">
      <div class="row navicons  text-center menu-top">
        <div class="col-menu"><router-link to="/"><i class="fas fa-home"></i></router-link></div>
        <div class="col-menu"><router-link to="/search"><i class="fas fa-search"></i></router-link></div>
        <div class="col-menu"><router-link to="/tinder"><i class="fas fa-user-friends"></i></router-link></div>
        <div class="col-menu"><router-link to="/messages"><i class="fas fa-comments"></i></router-link></div>
        <div class="col-menu"><router-link :to="'/profile/' + user.id + '/' + user.username"><i class="fas fa-user"></i></router-link></div>
        <div class="col-menu"><router-link to="/config"><i class="fas fa-user-edit"></i></router-link></div>
        <div class="col-menu"><router-link to="/notifications"><i class="fas fa-bell"></i></router-link></div>
        <div class="col-menu"><a @click="sair"><i class="fas fa-sign-out-alt"></i></a></div>
        <!--
        <div class="col-2" style="position: relative;" @mouseover="showOptions = true;" @mouseleave="showOptions = false;">
          <a><i class="fas fa-cog"></i></a>
          <div v-show="showOptions" class="submenu-top">
            <router-link to="/config"><i class="fas fa-user-edit"></i> Editar</router-link>
            <a @click="sair"><i class="fas fa-sign-out-alt"></i> Sair</a>
          </div>
        </div>-->
      </div>
    </div>
    <div class="container">
      <div class="loader" v-show="$store.state.load"></div>
      <router-view />
    </div>
  </div>
  <div v-else>
    <div class="loader" v-show="$store.state.load"></div>
    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import { me, logout, message } from './resources/fn'
import { success, error } from './resources/functions'

export default {
  name: 'App',
  data () {
    return {
      showOptions: false,
      message: '',
      lang: null
    }
  },
  beforeMount () {
    console.log('0.0.1')
    this.SET_LOAD(true)
    this.setLAng()
    me(this.axios, this.login).then((res) => {
      this.SET_USER(res.data)
      if (!this.$router.options.routes.find(a => a.path.split('/')[1] === window.location.pathname.split('/')[1]).auth) {
        this.$router.replace('/')
      }
    }, () => {
      this.SET_USER('')
      if (this.$router.options.routes.find(a => a.path.split('/')[1] === window.location.pathname.split('/')[1]).auth) {
        this.$router.replace('/login')
      }
    })
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  watch: {
    /*
    '$store.state.modalLikes' () {
    }
    */
  },
  methods: {
    ...mapMutations([
      'SET_USER',
      'SET_LOAD',
      'SET_MODAL_LIKES',
      'SET_MODAL_MSG'
    ]),
    setLAng () {
      if (!this.lang) {
        this.lang = window.navigator.language || window.navigator.browserLanguage
      }
      if (this.lang.indexOf('en') > -1) {
        this.lang = 'en'
      } else {
        this.lang = 'pt'
      }
      this.$i18n.setLocale(this.lang)
    },
    sair () {
      logout(this.axios).then((res) => {
        this.SET_USER('')
        this.$router.replace('/login')
      }, () => {})
    },
    sendMsg () {
      this.SET_LOAD(true)
      message(this.axios, this.$route.params.id, this.$route.params.user, { message: this.message }).then((res) => {
        success(this, 'Mensagem enviada com sucesso')
        this.SET_LOAD()
        this.SET_MODAL_MSG(0)
      }, (res) => {
        error(this, 'nao foi possivel enviar a mensagem')
        this.SET_LOAD()
      })
    }
  }
}
</script>
