export function success (el, title) {
  el.$swal.fire({
    position: 'top-end',
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500,
    toast: true,
    stopKeydownPropagation: false
  })
}

export function error (el, code) {
  let msg = ''
  switch (code) {
    case '1':
      msg = 'Utilizador, E-mail ou Palavra Passe incorrectos.'
      break
    case '2':
      msg = 'Utilizador não existe'
      break
    case '3':
      msg = 'Perfil Privado'
      break
    case '4':
      msg = 'Objecto não percente ao utilizador'
      break
    case '5':
      msg = 'Muita actividade por hoje'
      break
    case '6':
      msg = code
      break
  }
  el.$swal.fire({
    icon: 'error',
    title: msg || code,
    showConfirmButton: true,
    // timer: 1500,
    stopKeydownPropagation: false
  })
}
