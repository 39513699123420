import { createStore } from 'vuex'

export default createStore({
  state: {
    user: '',
    load: false,
    modalLikes: false,
    likes: [],
    modalMsg: false
  },
  mutations: {
    SET_USER (state, dta) {
      state.user = dta
    },
    SET_LOAD (state, dta) {
      state.load = dta
    },
    SET_MODAL_LIKES (state, dta) {
      state.modalLikes = dta
    },
    SET_LIKES (state, dta) {
      state.likes = dta
    },
    SET_MODAL_MSG (state, dta) {
      state.modalMsg = dta
    }
  },
  actions: {
  },
  modules: {
  }
})
