import { createApp } from 'vue'
import MasonryWall from '@yeger/vue-masonry-wall'
import VueLazyLoad from 'vue3-lazyload'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import VueFinalModal from 'vue-final-modal'
import Vue3TouchEvents from 'vue3-touch-events'
import FileInput from 'vue3-simple-file-input'

import 'sweetalert2/dist/sweetalert2.min.css'
import { createI18n } from 'vue3-i18n'

import App from './App.vue'
import router from './router'
import store from './store'

import en from './langs/en/translations.json'
import pt from './langs/pt/translations.json'

const i18n = createI18n({
  locale: 'pt', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en: en,
    pt: pt
  }
})

createApp(App)
  .use(store).use(router)
  .use(MasonryWall)
  .use(VueLazyLoad, {
  // options...
  })
  .use(VueAxios, axios)
  .use(i18n)
  .use(VueSweetalert2)
  .use(VueFinalModal)
  .use(Vue3TouchEvents)
  .component('FileInput', FileInput)
  .directive('data1', {
    beforeMount (el, binding) {
      el.innerHTML = el.innerHTML + '--'
    }
  })
  .mount('#app')

// @nuxtjs/device -> detectar browser, browser, sistema operativo, marca.....
