import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth.vue'),
    auth: false
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/auth.vue'),
    auth: false
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/auth.vue'),
    auth: false
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    auth: true
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat.vue'),
    auth: true
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('../views/config.vue'),
    auth: true
  },
  {
    path: '/post/create',
    name: 'postCreate',
    component: () => import('../views/post-create.vue'),
    auth: true
  },
  {
    path: '/post/:id/:user/:id_post',
    name: 'post',
    component: () => import('../views/post.vue'),
    auth: true
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('../views/messages.vue'),
    auth: true
  },
  {
    path: '/message/:id/:user',
    name: 'message',
    component: () => import('../views/message.vue'),
    auth: true
  },
  {
    path: '/profile/:id/:user',
    name: 'profile',
    component: () => import('../views/profile.vue'),
    auth: true
  },
  {
    path: '/profiles',
    name: 'profiles',
    component: () => import('../views/profiles.vue'),
    auth: true
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue'),
    auth: true
  },
  {
    path: '/tinder',
    name: 'tinder',
    component: () => import('../views/tinder.vue'),
    auth: true
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/notifications.vue'),
    auth: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  hashbang: false,
  base: __dirname,
  transitionOnLoad: true,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

export default router
