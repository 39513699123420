const URL = 'http://tlack.com/'

export function logout (f) {
  return f.post(URL + 'logout')
}
// auth
export function login (f, data) {
  return f.post(URL + 'login', data)
}
export function register (f, data) {
  return f.post(URL + 'register', data)
}

// users
export function me (f, userId, username) {
  return f.post(URL + '/u/me')
}
export function user (f, userId, username) {
  return f.post(URL + 'u/get/' + userId + '/' + username)
}
export function userUpdate (f, data) {
  return f.post(URL + 'u/update', data)
}
export function search (f, data) {
  return f.post(URL + 'u/search', data)
}

// follows
export function follow (f, userId, username) {
  return f.post(URL + 'u/follow/' + userId + '/' + username)
}
export function unfollow (f, userId, username) {
  return f.post(URL + 'u/unfollow/' + userId + '/' + username)
}
export function unblock (f, userId, username) {
  return f.post(URL + 'u/unblock/' + userId + '/' + username)
}
export function followers (f, userId, username) {
  return f.post(URL + 'u/followers/' + userId + '/' + username)
}
export function followAccept (f, userId, username) {
  return f.post(URL + 'u/accept/' + userId + '/' + username)
}
export function followMy (f) {
  return f.post(URL + 'u/my-follows')
}
export function followBlocks (f) {
  return f.post(URL + 'u/my-blocks')
}

// posts
export function postsCreate (f, data) {
  return f.post(URL + 'p/create', data)
}
export function postsEdit (f, data) {
  return f.post(URL + 'p/edit', data)
}
export function postsDelete (f, data) {
  return f.post(URL + 'p/create', data)
}
export function postsUser (f, userId, username) {
  return f.post(URL + 'p/get/' + userId + '/' + username)
}
export function postsComments (f, userId, username, postId, lastId) {
  return f.post(URL + 'p/comments/' + userId + '/' + username + '/' + postId + '?last_id=' + lastId)
}
export function postsLikes (f, userId, username, postId) {
  return f.post(URL + 'p/likes/' + userId + '/' + username + '/' + postId)
}
export function post (f, userId, username, postId) {
  return f.post(URL + 'p/get/' + userId + '/' + username + '/' + postId)
}
export function posts (f, data) {
  return f.post(URL + 'p/feed', data)
}
export function cronjob (f) {
  return f.post(URL + 'cronjob')
}

// comments
export function comments (f, userId, username, data) {
  return f.post(URL + 'c/comment/' + userId + '/' + username, data)
}
export function commentsEdit (f, data) {
  return f.post(URL + 'c/edit', data)
}
export function commentsDelete (f, data) {
  return f.post(URL + 'c/delete', data)
}

// likes
export function like (f, userId, username, data) {
  return f.post(URL + 'l/like/' + userId + '/' + username, data)
}
export function likeDelete (f, userId, username, data) {
  return f.post(URL + 'l/delete/' + userId + '/' + username, data)
}

// messages
export function messages (f, data) {
  return f.post(URL + 'm/inbox', data)
}
export function messagesOpen (f, userId, username, data) {
  return f.post(URL + 'm/open/' + userId + '/' + username, data)
}
export function messagesDelete (f, userId, username) {
  return f.post(URL + 'm/delete/' + userId + '/' + username)
}
export function message (f, userId, username, data) {
  return f.post(URL + 'm/send/' + userId + '/' + username, data)
}

// match
export function matchFeed (f, sex) {
  return f.post(URL + 'g/feed/' + sex)
}
export function matchPlay (f, userId, username, data) {
  return f.post(URL + 'g/play/' + userId + '/' + username, data)
}
export function match (f, data) {
  return f.post(URL + 'g/match/', data)
}
export function matchDelete (f, matchHash) {
  return f.post(URL + 'g/delete/' + matchHash)
}

// Notifications
export function notifications (f, data) {
  return f.post(URL + 'n/get', data)
}
export function notificationRead (f, type, userId) {
  return f.post(URL + 'n/read/' + type + '/' + userId)
}
